<template>
  <div class="empty1">
    <van-dialog v-model="showTip1" title=""   :before-close="beforeClose2"  confirmButtonColor="#3a68f2">
      {{tip2}}
    </van-dialog>
  </div>
</template>

<script>
export default {
    data(){
        return{
            showTip1:false,
            tip2:"" 
        }
    },
    mounted(){
        if(this.$route.query.content){
            this.showTip1 = true;
            this.tip2 = decodeURIComponent(this.$route.query.content)
        }
    },
    methods: {
        beforeClose2(){
            this.showTip1 = false
        }
    }
}
</script>
<style scope lang="less">
.empty1 {
  /deep/.van-dialog__content {
      padding: 20px!important;
  }
  .btn1{
    .van-dialog__content {
       text-align: left;
    }
    border-radius: 4px;
    padding: 10px;
    .van-dialog__footer{
        text-align: right;
        display: block;
    }
    .van-button{
        width: fit-content;
        flex: none;
        float: right;
        color: #fff;
        height: auto;
        background-color: #1890ff;
        padding: 10px 20px;
        border-radius: 3px;
        .van-button__text{
            font-size: 12px;
        }
    }
  }
 .van-dialog__content {
    padding: 20px!important;
  }
}
</style>