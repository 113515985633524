import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import { Image , Tabbar, TabbarItem ,Button, NavBar,Swipe, SwipeItem,Form,Checkbox, CheckboxGroup} from 'vant';
import Vant from "vant";
import "vant/lib/index.css";
import eruda from "eruda";
// eruda.init();
import echarts from "echarts";
import x2js from "x2js"; //xml数据处理插件
Vue.prototype.$x2js = new x2js(); //创建x2js对象，挂到vue原型上
Vue.use(Vant,echarts);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
