import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./../views/Home.vue";
import Index from "./../views/index.vue";
import Ribao from "./../views/ribao.vue";
import ribaoTYpe from "./../views/ribaoTYpe.vue";

import Xiangsd from "./../views/xiangsd.vue";
import Xiangsd_inde from "./../views/xiangsd_inde.vue";
import TaskDescription from "./../views/taskDescription.vue";
import queryIdDetail from "./../views/queryIdDetail.vue";
import select from "./../views/select.vue";
import selectDate from "./../views/selectDate.vue";
import rediect from "./../views/rediect.vue";
import empty from "./../views/empty.vue";
import empty1 from "./../views/empty1.vue";
import empty2 from "./../views/empty2.vue";
import empty3 from "./../views/empty3.vue";
import empty4 from "./../views/empty4.vue";
import richeng from "./../views/richeng.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "empty",
    component: empty,
  },
  {
    path: "/empty1",
    name: "empty1",
    component: empty1,
  },
  {
    path: "/richeng",
    name: "richeng",
    component: richeng,
  },
  {
    path: "/empty2",
    name: "empty2",
    component: empty2,
  },
  {
    path: "/empty3",
    name: "empty3",
    component: empty3,
  },
  {
    path: "/empty4",
    name: "empty4",
    component: empty4,
  },
  {
    path: "/Index",
    name: "Index",
    component: Index,
  },
  {
    path: "/empty",
    name: "empty",
    component: empty,
  },
  {
    path: "/queryIdDetail",
    name: "queryIdDetail",
    component: queryIdDetail,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/select",
    name: "select",
    component: select,
  },
  {
    path: "/selectDate",
    name: "selectDate",
    component: selectDate,
  },
  {
    path: "/index",
    name: "Index",
    component: Index,
  },
  
  {
    path: "/ribao",
    name: "Ribao",
    component: Ribao,
  },
  {
    path: "/ribaoTYpe",
    name: "ribaoTYpe",
    component: ribaoTYpe,
  },
  {
    path: "/xiangsd",
    name: "XSiangsd",
    component: Xiangsd,
  },
  {
    path: "/xiangsd_inde",
    name: "XSiangsd_inde",
    component: Xiangsd_inde,
  },
  {
    path: "/taskDescription",
    name: "TaskDescription",
    component:TaskDescription,
  },
  {
    path: "/rediect",
    name: "rediect",
    component:rediect,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
