<template>
  <div class="empty3">
    <van-overlay :show="showloading" @click="showloading = false">
      <div class="wrapper" @click.stop>
        <van-loading
          v-if="showloading == true"
          class="loading"
          color="#1989fa"
          vertical
          >加载中...</van-loading
        >
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { submitLog,LogInsert1,getLogByLogDate, LogInsert,log_getNextDay, getToken,getTokenDD,asana_login,asana_login1,getCompanyInfo,getInfo } from "./../utils/api";
import { Dialog, Toast } from "vant";
export default {
  data(){
    return{
      currentDate_top:"",
      currentDate_top1:"",
      showloading:false
    }
  },
  methods:{
    canSubmit(){
      let that = this
      this.showloading = true
      LogInsert1({
        logDate:  that.currentDate_top,
        nextDate: that.currentDate_top1,
      }).then(res=>{
        this.showloading = false
        if(res.code == 200){
          this.$router.push({
            path:"empty4",
          })
        }else{
          res.msg = res.msg.replace(/;/g, '\r\n')
          Dialog.alert({
            className:"btn111",
            message: "请先去asana完成任务或调整计划\r\n\r\n<span class='btn222'>"+res.msg+"</span>",
            title:"有未完成的任务",
            confirmButtonColor:"#3a68f2"
          }).then(() => {
          });
        }
      })
    },
    timeFunc(content, type) {
      const date = new Date(content);
      const y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      const minute = date.getMinutes();
      const second = date.getSeconds();
      if (type === "date") {
        return y + "-" + m + "-" + d;
      }
      if (type === "time") {
        return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
      }
    },
     async login(code) {
      console.log('333')
      let that = this;
      getToken(code).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("token", res.token);
          if(res.user.companyId){
            if(!res.user.asanaName){
              localStorage.setItem("isH5","1")
              location.href = 'https://app.asana.com/-/oauth_authorize?client_id=1201450570166924&redirect_uri=https://pmsh5.ilohhas.com&response_type=code'
            }else{
              log_getNextDay(this.currentDate_top).then(res=>{
                if(res.code==200){
                  this.currentDate_top1 = res.data.nextDate
                  this.canSubmit()
                }else{
                  Dialog.alert({
                    message: "系统繁忙，请稍后再试",
                    confirmButtonColor:"#3a68f2"
                  }).then(() => {
                  });
                }
            })
            }
          }else{
            //  Dialog.alert({
            //       message: "该企业未开通，请联系管理员开通",
            //       confirmButtonColor:"#3a68f2"
            //   }).then(() => {
            //   });
            this.$router.push({
              path:"empty1",
              query:{
                content: encodeURIComponent("该企业未开通，请联系管理员开通")
              }
            })
          }
        }else{
          if(res.msg=="获取用户信息失败！"){

          }else{
             Dialog.alert({
                  message:"系统繁忙，请稍后再试",
                  confirmButtonColor:"#3a68f2"
              }).then(() => {
                //  location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf54d14636e137fd2&redirect_uri=https%3A%2F%2Fpmsh5.ilohhas.com%2FselectDate&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
              });
          }
          
            // this.$router.push({
            //   path:"empty1",
            //   query:{
            //     content: encodeURIComponent(res.msg)
            //   }
            // })
        }
      });
    },
  },
  mounted(){
    if(localStorage.getItem('name')){
      this.name = localStorage.getItem('name')
    }
    // localStorage.removeItem('token')
    localStorage.removeItem('isH5')
    localStorage.removeItem('logDetails')
    localStorage.removeItem('TomorrowFeedback')
    let day3 = null;
    // this.currentDate_top = this.timeFunc(new Date().getTime(), "date");
    let time1 = new Date().getTime()
    let time2 = new Date((this.timeFunc(new Date().getTime(), "date") +" 08:00").replace(/-/g, '/')).getTime()
    if(time1>=time2){
      this.currentDate_top = this.timeFunc(new Date().getTime(), "date");
    }else{
      this.currentDate_top = this.timeFunc((new Date().getTime()-24 * 60 * 60 * 1000), "date");
    }
    // if (new Date().getDay() == 5) {
    //     day3 = new Date();
    //     day3.setTime(day3.getTime() + 3 * 24 * 60 * 60 * 1000);
    //     this.currentDate_top1 =
    //       day3.getFullYear() +
    //       "-" +
    //       (day3.getMonth() + 1 < 10
    //         ? "0" + (day3.getMonth() + 1)
    //         : day3.getMonth() + 1) +
    //       "-" +
    //       (day3.getDate()< 10  ? "0" + day3.getDate() 
    //         : day3.getDate());
    //   } else if (new Date().getDay() == 6) {
    //     day3 = new Date();
    //     day3.setTime(day3.getTime() + 2 * 24 * 60 * 60 * 1000);
    //     this.currentDate_top1 =
    //       day3.getFullYear() +
    //       "-" +
    //       (day3.getMonth() + 1 < 10
    //         ? "0" + (day3.getMonth() + 1)
    //         : day3.getMonth() + 1) +
    //       "-" +
    //       (day3.getDate()< 10  ? "0" + day3.getDate() 
    //         : day3.getDate());
    //   } else {
    //     day3 = new Date();
    //     day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
    //     this.currentDate_top1 =
    //       day3.getFullYear() +
    //       "-" +
    //       (day3.getMonth() + 1 < 10
    //         ? "0" + (day3.getMonth() + 1)
    //         : day3.getMonth() + 1) +
    //       "-" +
    //       (day3.getDate()< 10  ? "0" + day3.getDate() 
    //         : day3.getDate());
    //   }
    this.login(this.$route.query.code);

  },
}
</script>

<style scope lang="less">
.btn111{
    .van-dialog__header{
      font-weight: bold;
    }
    .van-dialog__message{
      text-align: left;
    }
    .btn222{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
    }
  }
.empty3 {
 .van-overlay{
    background-color: transparent;
  }
  .loading {
    position: fixed;
    top: 40%;
    left: 45%;
  }
}
</style>