<template>
  <div>
    <div class="ribao_top">
      <div class="ribao_top_shi" @click="jianshi">前一天</div>
      <div class="ribao_top_xuashi" @click="shijian">
        <van-icon name="clock-o" size="14" />
        <span>{{ currentDate }}</span>
      </div>
      <div class="ribao_top_shi" @click="jiashi">后一天</div>
    </div>
    <van-popup
      v-model="showshi"
      @click-overlay="guanshaa"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        @confirm="queshi"
        @cancel="shixiao"
        @change="bianshi"
      />
    </van-popup>
    <div id="echars" :style="{ width: '100%', height: '400px' }"></div>
    <div class="xiangiqg">
      <div class="xiafg">
        <div class="isanga">
          <img src="./../assets/image/icon_mom.png" alt="" />
          <span>灰原哀</span>
          <div>
            <p>查看时长：12 分钟</p>
            <p>任务反馈：1 条</p>
          </div>
        </div>
        <div>420min</div>
      </div>
      <div class="xiafg">
        <div class="isanga">
          <img src="./../assets/image/icon_mom.png" alt="" />
          <span>灰原哀</span>
          <div>
            <p>查看时长：12 分钟</p>
          </div>
        </div>
        <div>420min</div>
      </div>
      <div class="xiafg">
        <div class="isanga">
          <img src="./../assets/image/icon_mom.png" alt="" />
          <span>灰原哀</span>
          <div>
            <p>查看时长：12 分钟</p>
            <p>任务反馈：1 条</p>
          </div>
        </div>
        <div>420min</div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import topa from "./../components/HelloWorld.vue";
export default {
  props: {},
  data() {
    return {
      showshi: false,
      currentDate: "",
      activeribao: 0,
      ribaoinput: "67",
      tianshu: 7,
      rixiaoxian: [{}, {}, {}, {}, {}], //控制下面的内容小时影藏
      rixiaoxians: [true, true, true, true, true], //控制下面的内容小时影藏
    };
  },
  created() {
    this.currentDate = this.timeFunc(new Date().getTime(), "date");
  },
  mounted() {
    var myChart = echarts.init(document.getElementById("echars"));
    // 绘制图表
    myChart.setOption({
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "right",
      },
      series: [
        {
          name: "访问来源",
          type: "pie",
          radius: "50%",
          data: [
            { value: 1048, name: "搜索引擎" },
            { value: 735, name: "直接访问" },
            { value: 580, name: "邮件营销" },
            { value: 484, name: "联盟广告" },
            { value: 300, name: "视频广告" },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    });
  },
  methods: {
    // 点击弹出时间选择器;
    shijian() {
      this.showshi = true;
      this.currentDate = this.timeFunc(new Date().getTime(), "date");
      console.log(this.currentDate);
    },
    // 当点击时间确认按钮时的事件
    queshi(e) {
      console.log(new Date());
      let shijian = this.timeFunc(new Date(e).getTime(), "date");
      console.log(shijian);
      this.currentDate = shijian;
      this.showshi = false;
    },
    // 关闭弹出框事件
    guanshaa() {
      this.currentDate = this.timeFunc(new Date().getTime(), "date");
    },
    // 当时间发生改变时
    bianshi() {
      let shijian = this.timeFunc(new Date(this.currentDate).getTime(), "date");
      console.log(shijian);
      this.currentDate = shijian;
    },
    // 点击时间取消按钮时
    shixiao() {
      this.currentDate = this.timeFunc(new Date().getTime(), "date");
      this.showshi = false;
    },
    // 点击增加一天时
    jiashi() {
      console.log(this.currentDate);
      let shi = new Date(this.currentDate).getTime() + 86400000;
      console.log(shi);
      let shijian = this.timeFunc(shi, "date");
      console.log(shijian);
      this.currentDate = shijian;
    },
    // 点击减少一天
    jianshi() {
      console.log(this.currentDate);
      let shi = new Date(this.currentDate).getTime() - 86400000;
      console.log(shi);
      let shijian = this.timeFunc(shi, "date");
      console.log(shijian);
      this.currentDate = shijian;
    },
    // 时间处理函数
    timeFunc(content, type) {
      const date = new Date(content);
      const y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      console.log(d);
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      const minute = date.getMinutes();
      const second = date.getSeconds();
      if (type === "date") {
        return y + "-" + m + "-" + d;
      }
      if (type === "time") {
        return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
      }
    },
    // 点击下面的显示隐藏
    rixiao(e, a) {
      let tianshu = this.tianshu;
      if (a) {
        this.tianshu = a;
      } else {
        this.tianshu = tianshu;
      }
      let arr = this.rixiaoxians[e];
      arr = !arr;
      this.rixiaoxians.splice(e, 1, arr);
    },
  },

  components: {
    topa,
  },
};
</script>

<style scoped lang="less">
.ribao_top {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(224, 227, 234, 100);
  box-sizing: border-box;
}
.ribao_top_xuashi {
  width: 59%;
  height: 100%;
  border-right: 1px solid rgba(224, 227, 234, 100);
  border-left: 1px solid rgba(224, 227, 234, 100);
  box-sizing: border-box;
  color: rgba(184, 188, 197, 100);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ribao_top_shi {
  width: 20%;
  font-size: 14px;
  box-sizing: border-box;
}
.xiangiqg {
  width: 95%;
  margin: 0 auto;
}
.xiangiqg img {
  width: 34px;
}
.xiafg {
  width: 100%;
  height: 40px;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(187, 187, 187, 100);
  padding-bottom: 9px;
  margin-top: 9px;
}
.xiafg p {
  color: rgba(180, 180, 182, 100);
  font-size: 12px;
  margin: 0;
  padding: 0;
  text-align: left;
}
.isanga {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: space-between;
}
</style>
<style>
.ribaoa .van-tabs__line {
  width: 0;
}
.ribaoa .van-tab--active {
  background: #427bee 1%;
}
.ribaoa .van-tab {
  border-radius: 10px;
}
.ribaoa .van-tabs--line .van-tabs__wrap {
  width: 80%;
  border: 1px solid #427bee;
  border-radius: 14px;
  margin: 0 auto;
}
</style>