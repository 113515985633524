<template>
  <div>
    <!-- <van-tabs v-model="activeName"> -->
    <!-- <van-tab title="任务描述" style="border-top: 1px solid #e4e4e4" name="a"> -->
    <div class="t_head">
      <p>
        <img src="./../assets/icon/facility.png" alt="" />
        <span>任务名称：</span>
        <span>{{ TaskByTask.taskName }}</span>
      </p>
      <p>
        <img src="./../assets/icon/select.png" alt="" />
        <span>任务状态：</span>
        <span>{{ !!TaskByTask.status ? "进行中" : "已完成" }}</span>
      </p>
      <!-- <p>
            <img src="./../assets/icon/date.png" alt="" />
            <span>下一执行时间：</span>
            <span>2021/11/11</span>
          </p> -->
    </div>
    <div class="line"></div>
    <div class="t_main">
      <p style="white-space: pre-wrap" v-html="TaskByTask.description"></p>
    </div>
    <div class="comment_detaile">
      <div
        class="comment_list"
        v-for="item in TaskByTask.taskStories"
        :key="item.id"
      >
        <div>
          <div class="comment_list_top">
            <div class="comment_list_top_left">
              <img :src="item.avatar" class="comment_list_top_left_img" />
              <div class="comment_list_top_left_name">
                <div>{{ item.asanaName }}</div>
                <div>{{ item.createAt }}</div>
              </div>
            </div>
            <div class="comment_list_top_right">
              <van-icon name="good-job-o" />
              <div>({{ item.numLikes }})</div>
            </div>
          </div>
          <div class="comment_list_center">{{ item.storyText }}</div>
          <!-- <div class="comment_list_borrom">
              丽莎 （Lisa）changd the due date to 28 6 days
            </div> -->
        </div>
      </div>
    </div>
    <!-- </van-tab> -->

    <!-- <van-tab
        title="完成情况"
        style="border-top: 1px solid #cfd2dd; border-bottom: 1px solid #cfd2dd"
        name="b"
      >
       <div class="step">
          <div
            class="step_list"
            v-for="item in TaskByTask.taskStories"
            :key="item.id"
          >
            <div class="step_list_left">{{ item.createAt }}</div>
            <div class="step_list_dian"></div>
            <div class="step_list_right">
              <div class="step_list_right_user">
                <img
                  class="user_img"
                  src="https://img01.yzcdn.cn/vant/cat.jpeg"
                  alt=""
                />
                <div class="user_content">姓名：{{ item.asanaName }}</div>
              </div>
              <div class="user_content">{{ item.storyText }}</div>
            </div>
          </div> -->
    <!-- <div class="step_list">
            <div class="step_list_left">2021-12-30 13:53:44</div>
            <div class="step_list_dian"></div>
            <div class="step_list_right">
              <div class="step_list_right_user">
                <img
                  class="user_img"
                  src="https://img01.yzcdn.cn/vant/cat.jpeg"
                  alt=""
                />
                <div class="user_content">姓名</div>
              </div>
              <div class="user_content">属性后会在图标右上角展示相应的徽标</div>
            </div>
          </div>
          <div class="step_list">
            <div class="step_list_left">2021-12-30 13:53:44</div>
            <div class="step_list_dian"></div>
            <div class="step_list_right">
              <div class="step_list_right_user">
                <img
                  class="user_img"
                  src="https://img01.yzcdn.cn/vant/cat.jpeg"
                  alt=""
                />
                <div class="user_content">姓名</div>
              </div>
              <div class="user_content">属性后会在图标右上角展示相应的徽标</div>
            </div>
          </div>
          <div class="step_list">
            <div class="step_list_left">2021-12-30 13:53:44</div>
            <div class="step_list_dian"></div>
            <div class="step_list_right">
              <div class="step_list_right_user">
                <div class="user_content">
                  属性后会在图标右上角展示相应的徽标
                </div>
              </div>
            </div>
          </div> -->
    <!-- <div class="step_list">
            <div class="step_list_left">2021-12-30 13:53:44</div>
            <div class="step_list_dian"></div>
            <div class="step_list_right">
              <div class="step_list_right_user">
                <img
                  class="user_img"
                  src="https://img01.yzcdn.cn/vant/cat.jpeg"
                  alt=""
                />
                <div class="user_content">姓名</div>
              </div>
              <div class="user_content">属性后会在图标右上角展示相应的徽标</div>
            </div>
          </div>
          <div class="step_list">
            <div class="step_list_left">2021-12-30 13:53:44</div>
            <div class="step_list_dian"></div>
            <div class="step_list_right">
              <div class="step_list_right_user">
                <img
                  class="user_img"
                  src="https://img01.yzcdn.cn/vant/cat.jpeg"
                  alt=""
                />
                <div class="user_content">姓名</div>
              </div>
              <div class="user_content">属性后会在图标右上角展示相应的徽标</div>
            </div>
          </div>
          <div class="step_list">
            <div class="step_list_left">2021-12-30 13:53:44</div>
            <div class="step_list_dian"></div>
            <div class="step_list_right">
              <div class="step_list_right_user">
                <div class="user_content">
                  属性后会在图标右上角展示相应的徽标
                </div>
              </div>
            </div>
          </div>
          <div class="step_list">
            <div class="step_list_left">2021-12-30 13:53:44</div>
            <div class="step_list_dian"></div>
            <div class="step_list_right">
              <div class="step_list_right_user">
                <div class="user_content">
                  属性后会在图标右上角展示相应的徽标
                </div>
              </div>
            </div>
          </div> 
        </div>
        <div></div>
      </van-tab>-->
    <div class="t_foot">
      <van-search
        v-model="value"
        show-action
        shape="round"
        placeholder="评论一下~"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">发送</div>
        </template>
      </van-search>
    </div>
    <van-overlay :show="showloading" @click="showloading = false">
      <div class="wrapper" @click.stop>
        <van-loading
          v-if="showloading == true"
          type="spinner"
          class="loading"
          color="#1989fa"
          vertical
          >提交中...</van-loading
        >
      </div>
    </van-overlay>
    <!-- </van-tabs> -->
  </div>
</template>

<script>
import topa from "./../components/HelloWorld.vue";
import { task_getTaskByTaskId, task_story } from "./../utils/api";
import { Toast } from "vant";

export default {
  components: { topa },
  props: {},
  data() {
    return {
      activeName: "a",
      showloading: false,
      imgurl: "http://pmsapi.ilohhas.com",
      show: false,
      type: this.$route.query.type ? this.$route.query.type : "",
      date: "",
      value1: 0,
      TaskByTask: "",
      option1: [
        { text: "运营部", value: 0 },
        { text: "人事部", value: 1 },
        { text: "财务部", value: 2 },
        { text: "靑创部", value: 3 },
        { text: "客户成功部", value: 4 },
      ],
      componentName: "order",
      tabList: [
        { tabName: "已提交(12人)", componentName: "order" },
        { tabName: "未提交(13人)", componentName: "comment" },
      ],
      userForm: {
        orderFormid: "",
      },
      userObj: {},
      value: "",
      TaskBydetale: "",
    };
  },
  mounted() {
    let TaskBydetale = JSON.parse(this.$route.query.type);
    this.TaskBydetale = TaskBydetale;
    console.log(JSON.parse(this.$route.query.type));
    this.task_getTaskByTaskId(TaskBydetale.taskId);
  },
  methods: {
    switchType() {
      if (this.type == 2) {
        return "请小爱剪辑核贾岗";
      } else {
        return "凯乐士会后行动";
      }
    },
    // 获取任务信息详情
    task_getTaskByTaskId(e) {
      task_getTaskByTaskId(e).then((res) => {
        console.log(res);
        this.TaskByTask = res.data;
      });
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.formatDate(date);
    },
    onSearch(e) {
      this.showloading = true;
      console.log(this.value);
      if (!this.value) return;
      let a = {
        content: this.value,
        taskId: this.TaskByTask.taskId,
      };

      task_story(a).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.showloading = false;
          Toast("评论成功，请稍后刷新页面查看");
          this.task_getTaskByTaskId(this.TaskByTask.taskId);
          this.value = "";
        } else {
          Toast("系统繁忙，请稍后再试");
        }
      });
    },
    ok() {},
    no() {},
    async changeTab(item) {
      this.componentName = item.componentName;
      // if (this.componentName === "comment") {
      //   const com = await getComment({
      //     buyerUserId: this.buyerUserId,
      //     page: this.page,
      //     pageSize: this.pageSize,
      //   });
      //   this.commentS = com.data.list;
      //   console.log("TA的评论", com);
      // } else {
      //   const Address = await getAddress(this.buyerUserId);
      //   console.log("TA的收货地址", Address);
      //   this.addressInfoS = Address.data;
      // }
    },
  },
};
</script>

<style scoped lang="less">
.tab_list {
  // overflow: hidden;
  width: 100%;
  padding: 0 5%;

  p {
    // margin: 0 auto;
    float: left;
    width: 45%;
    // padding: 0 50px;
    font-size: 16px;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px;

    &:nth-child(-n + 2) {
      border-right: 0;
    }

    &:nth-child(1) {
      border-radius: 4px 0 0 4px;
    }

    &:nth-child(3) {
      border-radius: 0 4px 4px 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .active {
    background-color: #3a68f2;
    border: 0;
  }
}

.t_head {
  // border-bottom: 1px solid #cfd2dd;
  padding: 0 18px;

  p {
    width: 100%;
    height: 30px;
    font-size: 14px;
    line-height: 20px;
    position: relative;

    img {
      width: 14px;
      height: 14px;
      color: #000;
      position: absolute;
      top: 3px;
      left: 10px;
    }

    span {
      position: absolute;
      top: 0;
      left: 130px;
      color: #3a68f2;
      font-weight: bold;
      text-align: left;
      display: inline-block;
      height: auto;
      &:nth-child(2) {
        position: absolute;
        top: 0;
        left: 30px;
        color: #000;
      }
    }
  }
}

.t_main {
  padding: 0 18px;
  padding-bottom: 30px;
  text-align: left;
  p {
    &:nth-child(1) {
      width: 100%;
      /*height: 30px;*/
      font-size: 16px;
      line-height: 30px;
      word-break: break-all;
      word-wrap: break-word;
    }

    &:nth-child(2) {
      width: 100%;
      /*height: 30px;*/
      font-size: 16px;
      line-height: 30px;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.t_foot {
  position: fixed;
  border-top: 1px solid #eeeeee;
  width: 100%;
  bottom: 0px;
  .van-search--show-action {
    padding-right: 12px;
  }
  .van-search {
    width: 100%;
  }
  .van-search__action {
    width: 60px;
    height: 30px;
    box-sizing: border-box;
  }
}

.step {
  padding: 10px;
  font-size: 14px;
  margin-bottom: 60px;
  .step_list {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 40px;
    .step_list_dian {
      position: absolute;
      top: 0;
      left: 27%;
      color: #3a68f2;
      background-color: #f7f9fa;
      height: 100%;
      display: flex;
      align-items: center;
      width: 3px;

      &:after {
        content: "●";
        position: absolute;
        top: 50%;
        left: -2.5px;
        transform: translateY(-50%);
      }
    }

    .step_list_left {
      width: 30%;
    }

    .step_list_right {
      width: 70%;
      margin: 10px;
      padding: 10px;
      background: #f7f9fa;
      text-align: left;
      word-break: break-all;
      word-wrap: break-word;
      .step_list_right_user {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .user_img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .user_content {
          color: #727272;
          font-size: 14px;
        }
      }
    }
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #e4e4e4;
}

.van-cell-group {
  position: fixed;
  width: 95%;
  bottom: 10px;

  .van-icon {
    z-index: 999;
    position: fixed;
    bottom: 10px;
    left: 30px;
  }
}

.van-cell-group .van-icon[data-v-caf842e6] {
  z-index: 999;
  position: fixed;
  bottom: 10px;
  right: -270px;
}
</style>

<style>
.van-tab__text--ellipsis {
  font-size: 16px;
  font-weight: bold;
  z-index: 999;
}
.comment_detaile {
  padding-bottom: 45px;
}
.comment_list {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e1e1;
}
.comment_list:last-child {
  border-bottom: none;
}
.comment_list_top {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment_list_top_left {
  width: 70%;
  height: 100%;
  overflow: hidden;
  display: flex;
}
.comment_list_top_left_img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 15px;
}
.comment_list_top_left_name div:first-child {
  font-size: 14px;
  text-align: left;
  font-weight: 600;
}
.comment_list_top_left_name div:last-child {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
}
.comment_list_top_right {
  font-size: 17px;
  height: 100%;
  text-align: start;
  display: flex;
}
.comment_list_center {
  font-size: 14px;
  font-weight: 600;
  min-height: 30px;
  text-align: left;
  margin-top: 8px;
  word-break: break-all;
  word-wrap: break-word;
}
.comment_list_borrom {
  font-size: 13px;
  text-align: left;
}
</style>

<style scoped>
/deep/ .van-tabs__line {
  position: absolute;
  bottom: 15px;
  left: 0;
  z-index: 99999;
  width: 40px;
  height: 3px;
  background-color: #3a68f2;
  border-radius: 3px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.loading {
  position: fixed;
  top: 50%;
  left: 45%;
}
/* /deep/ .van-cell {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      box-sizing: border-box;
      padding: 5px 0 5px 40px;
      overflow: hidden;
      color: #323233;
      font-size: 14px;
      line-height: 24px;
      background-color: #fff;
      border-radius: 10px 10px 10px 10px;
      width: 80%;
      height: 30px;
      background-color: red;
      margin: 0 40px 0 20px;
    } */
</style>


