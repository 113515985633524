<template>
  <div class="topa">
    <van-icon name="arrow-left" color="#fff" @click="fan" size="20"/>
    <div class="topa_zi">
      <slot></slot>
    </div>
    <img src="./../assets/icon/ren.png" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    previouspage:{
      
    },
    currentDate:{

    }
  },
  data() {
    return {};
  },
  methods: {
    fan(e) {
      console.log(this.previouspage,"");
      console.log(this.currentDate,"currentDatecurrentDatecurrentDatecurrentDate");
      if(this.previouspage){
        localStorage.setItem("currentDate",this.currentDate)
      }
     window.history.back(-1);
    },
  },
  components: {},
};
</script>

<style lang="less">
.topa {
  width: 100%;
  height: 53px;
  background: #427bee 1%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.topa img:first-child {
  width: 20px;
  height: 30px;
}
.topa img:last-child {
  width: 17.5px;
  height: 20px;
}
.topa_zi {
  width: 70%;
  height: 100%;
  line-height: 53px;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}
</style>
