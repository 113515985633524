<template>
  <div class="index">
    <!-- <div class="btn" @click="turnPath1">日报生成</div> -->
    <div class="ribao_top">
      <div class="ribao_top_shi" @click="jianshi">前一天</div>
      <div class="ribao_top_xuashi" @click="shijian">
        <van-icon name="clock-o" size="14" />
        <span>{{ currentDate_top }}</span>
      </div>
      <div class="ribao_top_shi" @click="jiashi">后一天</div>
    </div>
    <van-popup v-model="showshi" position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        @confirm="queshi"
        @cancel="shixiao"
        @change="bianshi"
      />
    </van-popup>

    <div class="echart_list">
      <select
        class="sele"
        name="sel"
        id="selectstation"
        @change="selectFn($event)"
      >
        <option
          v-for="item in option1"
          :value="item.id"
          id="first_options"
          :key="item.id"
        >
          {{ item.label }}
        </option>
      </select>
      <div id="myEchart" ref="myEchart" class="fullSize"></div>
    </div>

    <div class="ribaoa">
      <van-tabs
        v-model="activeribao"
        title-active-color="#fff"
        title-inactive-color="#6392F1"
      >
        <van-tab>
          <template #title>已提交({{ submitList.length }}人) </template>
          <div class="c">
            <p
              class="main"
              @click="turnPath(item)"
              v-for="(item, index) in submitList"
              :key="index"
            >
              <img src="https://img01.yzcdn.cn/vant/cat.jpeg" alt="" />
              <span>{{ item.userName }}</span>
              <span
                >计划/临时/反馈 {{ item.planCount }}/{{ item.tempCount }}/{{
                  item.feedbackCount
                }}
              </span>
              <span>{{ item.submitDate }}</span>
              <span>
                <van-icon name="arrow" />
              </span>
            </p>
          </div>
        </van-tab>

        <van-tab>
          <template #title>未提交({{ noSubmitList.length }}人) </template>
          <div class="ribaoxiangq">
            <p class="main" v-for="(item, index) in noSubmitList" :key="index">
              <!-- <p
              class="main"
              v-for="(item, index) in noSubmitList"
              :key="index"
              @click="turnPath(item)"
            > -->
              <img src="https://img01.yzcdn.cn/vant/cat.jpeg" alt="" />
              <span>{{ item.userName }}</span>
            </p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import topa from "./../components/HelloWorld.vue";
import $ from "axios";
import {
  logReport_viewReport,
  getTokenDD,
  dept_treeSelect,
} from "./../utils/api";
export default {
  components: { topa },
  props: {},
  data() {
    return {
      jsonCont: null, // 解析的mml的数据
      getSuiteToken: null, //需要的登录token值
      currentDate: new Date(new Date() - 24 * 60 * 60 * 1000),
      currentDate_top: new Date(new Date() - 24 * 60 * 60 * 1000),
      show: false,
      showshi: false,
      date: "",
      submitList: [], //已提交
      noSubmitList: [], //未提交
      option1: [],
      options_id: "",
      value: "",
      activeribao: 0,
      code: "",
    };
  },
  created() {
    console.log(
      localStorage.getItem("currentDate"),
      'localStorage.getItem("currentDate")localStorage.getItem("currentDate")localStorage.getItem("currentDate")localStorage.getItem("currentDate")'
    );
    if (localStorage.getItem("currentDate")) {
      this.currentDate_top = this.timeFunc(
        new Date(localStorage.getItem("currentDate")),
        "date"
      );
      this.currentDate = localStorage.getItem("currentDate");
      setTimeout(() => {
        localStorage.removeItem("currentDate");
      }, 1000);
    } else {
      this.currentDate_top = this.timeFunc(
        new Date(new Date() - 24 * 60 * 60 * 1000).getTime(),
        "date"
      );
    }

    if (!this.$router.query) {
      location.href =
        "https://login.dingtalk.com/oauth2/auth?redirect_uri=http%3A%2F%2Fpmsding.ilohhas.com%2F&response_type=code&client_id=suitec9zmthiivsgkb1da&scope=openid&state=dddd&prompt=consent";
    }
  },
  watch: {
    $route(res) {
      if (res.query.code) {
        console.log(res);
        this.login(res.query.code);
      }
    },
  },
  mounted() {
    // this.dept_treeSelect()
    // $.get('http://pmsapi.ilohhas.com​/web/wx/getUserByCode?code=ZcOmtuL3P1rd7vwYaDR5cknkW0sISNqUoGpQwcN3moE').then(res=>{
    //   debugger
    // })
    // this.Authorized();
    // this.get_LOgReprot(11, "2022-02-12");
    // this.dept_treeSelect();
  },
  methods: {
    turnPath1() {
      this.$router.push("select");
    },
    async Authorized() {
      // 登录授权获取code &response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect
      await $.get(
        "https://login.dingtalk.com/oauth2/auth?redirect_uri=http%3A%2F%2Fpmsding.ilohhas.com%2F&response_type=code&client_id=suitec9zmthiivsgkb1da&scope=openid&state=dddd&prompt=consent"
      ).then((res) => {});
    },
    async login(code) {
      let that = this;
      getTokenDD({'code':code}).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("token", res.token);
          this.dept_treeSelect();
        }
      });
    },
    // 日报信息查询
    get_LOgReprot(da, da2) {
      logReport_viewReport(da, da2).then((res) => {
        console.log(res);
        this.submitList = res.data.submitList;
        this.noSubmitList = res.data.noSubmitList;
        let xAxis_data = [];
        let series_yiti = [];
        let series_weiti = [];
        res.data.reportCounts.map((chart) => {
          // let y = new Date(chart.reportDate).getFullYear();
          // let m = new Date(chart.reportDate).getMonth() + 1;
          // let d = new Date(chart.reportDate).getDate();
          // console.log(d, "dddddddddddd");
          let date = this.timeFunc(chart.reportDate, "date");
          xAxis_data.push(date);
          series_yiti.push(chart.normal);
          series_weiti.push(chart.noSubmit);
        });
        var chartDom = document.getElementById("myEchart");
        var myChart = echarts.init(chartDom);
        var option;
        // 树状图数据
        option = {
          color: ["#3F95C2", "#6DC8E1"],
          grid: {
            left: "0",
            right: "0",
            bottom: "3%",
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["已提交", "未提交"],
            right: "2",
            itemHeight: 8,
            itemWidth: 8,
            textStyle: {
              fontSize: 10,
              fontWeight: "bolder",
            },
          },
          calculable: true,
          xAxis: {
            // X轴数据参数
            type: "category",
            data: xAxis_data,
          },
          yAxis: [
            // Y轴数据参数
            {
              type: "value",
            },
          ],
          series: [
            {
              // 每组数据第一列参数
              name: "已提交",
              type: "bar",
              barGap: 0,
              data: series_yiti,
            },
            {
              // 每组数据第二列参数
              name: "未提交",
              type: "bar",
              data: series_weiti,
            },
          ],
        };
        option && myChart.setOption(option);
      });
    },
    // 部门数据查询
    dept_treeSelect() {
      dept_treeSelect().then((res) => {
        console.log(res);
        this.options_id = res.data[0].id;
        this.get_LOgReprot(this.options_id, this.currentDate_top);7
        this.option1 = res.data;
      });
    },
    // 选择部门获取到的数据
    selectFn(e) {
      console.log(e);
      console.log(e.target.value); // 选择项的value
      this.options_id = e.target.value;
      this.get_LOgReprot(e.target.value, this.currentDate_top);
    },
    turnPath(e) {
      let id = {
        reportDate: e.reportDate,
        userId: e.userId,
        companyId: e.companyId,
        userName: e.userName,
      };
      this.$router.push({
        path: "ribaoTYpe",
        query: { id: e.userId, reportDate: e.reportDate },
      });
    },
    // 点击弹出时间选择器;
    shijian() {
      this.showshi = true;
      this.currentDate_top = this.timeFunc(
        new Date(this.currentDate_top).getTime(),
        "date"
      );
      this.currentDate = new Date(this.currentDate_top);
    },
    // 当点击时间确认按钮时的事件
    queshi(e) {
      console.log(new Date());
      let shijian = this.timeFunc(new Date(e).getTime(), "date");
      console.log(shijian);
      this.currentDate_top = shijian;
      this.showshi = false;
      this.get_LOgReprot(this.options_id, this.currentDate_top);
    },
    // 当时间发生改变时
    bianshi() {
      let shijian = this.timeFunc(
        new Date(this.currentDate_top).getTime(),
        "date"
      );
      this.currentDate_top = shijian;
    },
    // 点击时间取消按钮时
    shixiao() {
      this.currentDate_top = this.timeFunc(
        new Date(new Date() - 24 * 60 * 60 * 1000).getTime(),
        "date"
      );
      this.showshi = false;
    },
    // 点击增加一天时
    jiashi() {
      let shi = new Date(this.currentDate_top).getTime() + 86400000;
      let shijian = this.timeFunc(shi, "date");
      this.currentDate_top = shijian;
      this.get_LOgReprot(this.options_id, this.currentDate_top);
    },
    // 点击减少一天
    jianshi() {
      console.log(this.currentDate_top);
      let shi = new Date(this.currentDate_top).getTime() - 86400000;
      let shijian = this.timeFunc(shi, "date");
      this.currentDate_top = shijian;
      this.get_LOgReprot(this.options_id, this.currentDate_top);
    },
    // 时间处理函数
    timeFunc(content, type) {
      const date = new Date(content);
      const y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      const minute = date.getMinutes();
      const second = date.getSeconds();
      if (type === "date") {
        return y + "-" + m + "-" + d;
      }
      if (type === "time") {
        return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
      }
    },

    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.formatDate(date);
    },
    ok() {},
    no() {},
  },
};
</script>
<style scoped lang="less">
.index {
  padding-bottom: 45px;
}
.ribao_top {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(224, 227, 234, 100);
  box-sizing: border-box;
}
.btn {
  width: 100%;
  padding: 10px 0;
  position: fixed;
  right: 0px;
  bottom: 0px;
  font-size: 16px;
  font-weight: bold;
  background-color: #427bee;
  color: #fff;
  border-radius: 5px;
  z-index: 3;
}
.ribao_top_xuashi {
  width: 59%;
  height: 100%;
  border-right: 1px solid rgba(224, 227, 234, 100);
  border-left: 1px solid rgba(224, 227, 234, 100);
  box-sizing: border-box;
  color: rgba(184, 188, 197, 100);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ribao_top_shi {
  width: 20%;
  font-size: 14px;
  box-sizing: border-box;
}

.header {
  display: block;
  height: 50px;
}

.main {
  width: 95%;
  height: 53px;
  margin: 0 auto;
  display: flex;
  position: relative;
  border-bottom: 1px solid #e4e4e4;

  img {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0 10px;
    position: absolute;
    top: 17%;
    left: 10px;
  }

  span {
    &:nth-child(2) {
      font-size: 14px;
      position: absolute;
      top: 15px;
      left: 60px;
    }

    &:nth-child(3) {
      font-size: 12px;
      position: absolute;
      top: 22px;
      right: 50px;
      color: #3a68f2;
    }

    &:nth-child(4) {
      font-size: 12px;
      position: absolute;
      top: 35px;
      left: 60px;
      color: #b8babe;
    }

    &:nth-child(5) {
      font-size: 12px;
      position: absolute;
      top: 25px;
      right: 10px;
    }
  }

  .van-button {
    padding: 26px 40px;
    font-size: 14px;
  }
}

.tab_list {
  padding: 0 5%;

  p {
    float: left;
    width: 50%;
    font-size: 16px;
    border: 1px #3a68f2 solid;
    box-sizing: border-box;
    color: #3a68f2;
    height: 39px;
    line-height: 39px;

    &:nth-child(1) {
      border-radius: 4px 0 0 4px;
    }

    &:nth-child(3) {
      border-radius: 0 4px 4px 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .active {
    background-color: #3a68f2;
    color: #fff;
    border: 0;
  }
}

.echart_list {
  width: 90%;
  height: 197px;
  border: 1px solid rgba(224, 229, 235, 1);
  box-shadow: 0px 5px 10px 0px rgba(51, 51, 51, 0.15);
  margin: 10px auto;
  padding: 20px 10px;
  position: relative;

  .sele {
    position: absolute;
    top: 20px;
    left: 10px;
    z-index: 9;
  }

  .echart_item {
    padding-left: 20px;
    box-sizing: border-box;

    .title {
      font-size: 14px;

      span {
        &:nth-child(1) {
          float: left;
        }

        &:nth-child(2) {
          i {
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: green;
          }
        }

        &:nth-child(3) {
          float: right;

          i {
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: yellow;
          }
        }
      }
    }
  }

  #myEchart {
    width: 100%;
    height: 100%;
    // height: calc(100% - 100px);
    z-index: 0;
  }
}

.ribaoa {
  margin-top: 30px;
}

.ribaoxiangq {
  // width: 98%;
  font-size: 14px;
  margin: 0 auto;
  margin-top: 20px;
}
</style>

<style scoped>
/deep/ .ribaoa .van-tabs--line .van-tabs__wrap {
  z-index: 999;
  width: 90%;
  border: 1px solid #3a68f2;
}
</style>
