<template>
  <div class="empty2">

  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">

</style>